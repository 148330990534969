





















































import {Component, Vue, Watch} from "vue-property-decorator";
import StoreLayout from "../layouts/StoreLayout.vue";
import {InventoryItem} from "@/interfaces/entities/InventoryItem";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import {ActivateDataResponse} from "@/interfaces/httpResponses/ActivateDataResponse";
import TDSError from "@/components/common/TDSError.vue";

@Component({
    components: {TDSSpinner, TDSButton, StoreLayout, TDSError}
})
export default class POSStore extends Vue {
    private state: string = "";
    private profileLink: string = "";
    private activatedValue: string = "";
    private inventoryItem: InventoryItem | null = null;
    private note: string = "";
    private activationInProgress: boolean = false;
    private errorMessage: string = this.$t("posStore.error.message").toString();

    @Watch("$route.query.state")
    stateQueryChanged(val: string, oldValue: string) {

        //  If the user pushed "back" on success/error screen we skip
        //  the loading screen and go back to the /store package selection...
        if (val === "loading" && (oldValue === "error" || oldValue === "success" || !this.activationInProgress)) {
            this.state = "";
            this.$router.replace("/store");
        } else {
            setTimeout(() => this.$store.commit("SET_PREVIOUS_VIEW", "/store"));
            this.state = val;
        }
    }

    created() {
        if(!this.$route.params.type || !this.$store.state.validCustomerTypes.includes(this.$route.params.type)) this.$router.replace("/store");
        else if(this.$route.params.type === "new" && !this.$route.query.email && !(this.$route.query.state === "success" || this.$route.query.state === "error")) this.$router.replace("/store/new");
        else if(this.$route.params.type === "existing" && !this.$route.query.customerUid && !(this.$route.query.state === "success" || this.$route.query.state === "error")) this.$router.replace("/store/existing");
        this.$store.commit("SET_PREVIOUS_VIEW", "/store");
        this.fetchData();
        this.state = (this.$route.query.state ?? "") as string;
        if (this.state === "loading") {
            this.state = "";
        }
    }

    @Watch("$route.params")
    paramsChanged(value: {type?: string}) {
        if(!value.type || !this.$store.state.validCustomerTypes.includes(value.type)) this.$router.replace("/store");
        else if(value.type === "new" && !this.$route.query.email && !(this.$route.query.state === "success" || this.$route.query.state === "error")) this.$router.replace("/store/new");
        else if(value.type === "existing" && !this.$route.query.customerUid && !(this.$route.query.state === "success" || this.$route.query.state === "error")) this.$router.replace("/store/existing");
    }

    private async fetchData() {
        this.inventoryItem = await this.$store
            .dispatch("FETCH_INVENTORY_ITEM", this.$route.params.id);
    }

    private async confirmPayment() {
        await this.$router.push({query: Object.assign({}, this.$route.query, {state: "notes"})});
    }

    private async activatePackage() {
        this.activationInProgress = true;
        await this.$router.push({query: Object.assign({}, this.$route.query, {state: "loading"})});
        try {
            let data: ActivateDataResponse;
            if(this.$route.params.type === "existing") {
                data = await this.$store.dispatch("TOPUP_INVENTORY_ITEM", {
                    inventoryItemId: this.$route.params.id,
                    metatag: this.note,
                    customerUid: this.$route.query.customerUid,
                    expectedPrice: this.inventoryItem? this.inventoryItem.retailPrices[0] : null
                });
            } else {
                data = await this.$store.dispatch("ACTIVATE_INVENTORY_ITEM", {
                    inventoryItemId: this.$route.params.id,
                    metatag: this.note,
                    customerEmail: this.$route.params.type === "new" ? this.$route.query.email : undefined,
                    expectedPrice: this.inventoryItem? this.inventoryItem.retailPrices[0] : null
                });
            }
            if (data.status !== "success") {
                console.error("Status of eSIM profile activation isn't successful.");
                await this.$router.push({query: {state: "error"}});
                return;
            }
            this.profileLink = this.$route.params.type === "guest" ? data.esimProfile.installationUrl : data.customer.profileUrl;
            this.activatedValue = data.activatedItem.balance.size.sizeValue + " " + data.activatedItem.balance.size.sizeUnit;
            await this.$router.push({query: {state: "success"}});
        } catch (e) {
            console.error("[POSS] Something went wrong on activation: ", e);
            if(e.response?.data?.title === "Could not create customer") this.errorMessage = this.$t("posStore.error.customerErrorMessage").toString();
            if(e.message === "Request failed with status code 409") this.errorMessage = this.$t("posStore.error.priceMismatchMessage").toString();
            await this.$router.push({query: {state: "error"}});
        }
        this.activationInProgress = false;
    }

    private copyToClipBoard(text: string) {
        if (!text) return;
        const el = document.createElement("input");
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        this.$toastr.success(this.$t("components.inputfield.copied"), true, true);
    }
}
